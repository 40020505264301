import { graphql } from "gatsby"
import Img from "gatsby-image"
import { StaticImage } from "gatsby-plugin-image"
import { useTranslation } from "gatsby-plugin-react-i18next"
import * as React from "react"
import ReactGA from "react-ga"
import { Link } from "react-scroll"
import Slider from "react-slick"
import "slick-carousel/slick/slick-theme.scss"
import "slick-carousel/slick/slick.scss"
import "../../assets/scss/style.scss"
import Seo from "../components/seo"
import useOnScreen from "../hooks/useOnScreen"
import FF from "../images/ff.svg"
import GW from "../images/gw.svg"
import Email from "../images/ico01.svg"
import Location from "../images/ico02.svg"
import Loader from "../images/icon.svg"
import Logo from "../images/logo.svg"
import Menu from "../images/menu.svg"

const settings = {
  dots: true,
  infinite: true,
  speed: 400,
  slidesToShow: 5,
  slidesToScroll: 1,
  centerMode: true,
  vartical: false,
  autoplay: true,
  variableWidth: true,
  responsive: [
    {
      breakpoint: 1199,
      settings: {
        slidesToShow: 3,
      }
    },
    {
      breakpoint: 767,
      settings: {
        slidesToShow: 1,
      }
    },
  ]
}

const IndexPage = ({ data }) => {
  const isBrowser = typeof window !== "undefined"
  const { t } = useTranslation()
  const [loaded, setLoaded] = React.useState(false)
  const [scroll, setScroll] = React.useState(false)
  const [mobileMenuOpen, setMobileMenuOpen] = React.useState(false);
  const [contactActive, setContactActive] = React.useState(false)
  const bottomRef = React.useRef()
  const isVisible = useOnScreen(bottomRef)
  React.useEffect(() => {
    if (isBrowser)
      window.addEventListener("scroll", () => {
        setScroll(window.scrollY > 50)
      })
  }, [])
  React.useEffect(() => {
    ReactGA.initialize("UA-16170420-2")
    if (isBrowser)
      ReactGA.pageview(window.location.pathname + window.location.search)
  }, [])

  React.useEffect(() => {
    const onPageLoad = () => {
      setLoaded(true)
    }

    if (isBrowser)
      if (document.readyState === "complete") {
        // Check if the page has already loaded
        onPageLoad()
      } else {
        window.addEventListener("load", onPageLoad)
        // Remove the event listener when component unmounts
        return () => window.removeEventListener("load", onPageLoad)
      }
  }, [])

  const onMenuItemClick = () => {
    setMobileMenuOpen(false);
    document.getElementById("menu").checked = false;
  }
  
  return (
    <div>
      <Seo title="Copernicus Apps" />
      <header className={scroll||mobileMenuOpen ? "sticky" : ""}>
        <div
          onClick={() => {
            if (isBrowser) window.scrollTo(0, 0);
            onMenuItemClick();
          }}
          className="logo"
        >
          <Logo />
        </div>
        <input type="checkbox" id="menu" onClick={()=> setMobileMenuOpen(!mobileMenuOpen)}/>
        <label htmlFor="menu"><Menu/></label>
        <nav>
          <ul>
            <li >
              <Link
                activeClass={!isVisible ? "activeButton" : ""}
                spy
                to="portfolio"
                offset={-70}
                onClick={onMenuItemClick}
              >
                <p>Portfolio</p>
              </Link>
            </li>
            <li > 
              <Link
                activeClass={!isVisible ? "activeButton" : ""}
                spy
                to="applicationsSection"
                offset={-70}
                onClick={onMenuItemClick}
              >
                <p>Aplikacje</p>
              </Link>
            </li>
            <li >
              <Link
                activeClass="activeButton"
                spy
                to="contact"
                offset={-70}
                className={isVisible ? "activeButton" : ""}
                onClick={onMenuItemClick}
              >
                <p>Kontakt</p>
              </Link>
            </li>
          </ul>
        </nav>
      </header>
      <section className="portfolio" id="portfolio">
        <h2>Portfolio</h2>
        <h4>Poznaj nasze aplikacje</h4>
        <div className="slider">
          <StaticImage
            src="../images/phone1.png"
            alt=""
            className="phone"
            placeholder="none"
          />
          <Slider {...settings} classname="sliderComponent">
            <div>
              <Img fluid={data.image1.childImageSharp.fluid} />
            </div>
            <div>
              <Img fluid={data.image2.childImageSharp.fluid} />
            </div>
            <div>
              <Img fluid={data.image3.childImageSharp.fluid} />
            </div>
            <div>
              <Img fluid={data.image4.childImageSharp.fluid} />
            </div>
            <div>
              <Img fluid={data.image5.childImageSharp.fluid} />
            </div>
            <div>
              <Img fluid={data.image6.childImageSharp.fluid} />
            </div>
          </Slider>
        </div>
      </section>
      <section className="applications" id="applicationsSection">
        <div className="anchor" id="applications"></div>
        <h2>Aplikacje</h2>
        <div className="row">
          <div className="col col-2">
            <div className="gw">
              <GW />
              <p>
                Wyjątkowe przedmioty po drugiej stronie ulicy. Dzięki
                internetowi i naszej aplikacji dzisiaj każdy może mieć swój
                wirtualny garaż. Możesz mieć swój wirtualny garaż nie tylko w
                domu ale w każdym mieszkaniu, w każdym mieście i na każdej wsi.
                Możesz sprzedawać nie tylko ubrania, samochód czy mieszkanie ale
                każdą inną nową lub używaną rzecz.
              </p>
              <div className="button-container">
                <a
                  href="https://garazowewyprzedaze.pl/"
                  target="_blank"
                  className="button"
                >
                  Więcej
                </a>
              </div>
            </div>
          </div>
          <div className="col col-2">
            <div>
              <div className="ff-logo">
                <StaticImage
                  src="../images/ff-ico.png"
                  alt=""
                  class="ico"
                  placeholder="none"
                />
                <FF />
              </div>
              <p>
                Darmowe produkty spożywcze z Twojej wirtualnej lodówki. Kupiłeś
                lub zrobiłeś za dużo jedzenia? Nie masz już na coś ochoty?
                Chcesz zmienić dietę i styl życia? Masz pełną lodówkę a jutro
                wyjeżdżasz na urlop? Twoje dziecko jest uczulone lub nie chce
                smacznej kaszki? W łatwy sposób możesz już nie marnować jedzenia
                i dzielić się z innymi, pomagać potrzebującym i chronić planetę
                przed zmianami klimatycznymi.
              </p>
              <div className="button-container">
                <a
                  href="https://fullfridge.app/"
                  target="_blank"
                  className="button"
                >
                  Więcej
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
      <StaticImage
        src="../images/footer-bg.png"
        alt=""
        class="footer-bg"
        placeholder="none"
      />

      <footer className="contact" id="contact">
        <div className="anchor"></div>
        <h2 className="light">Kontakt</h2>
        <div className="row">
          <div className="col col-2">
            <div className="info">
              <Email />
              <h4>office@copernicusapps.com</h4>
            </div>
          </div>
          <div className="col col-2">
            <div className="info">
              <Location />
              <h4>Chlebowa 33/6, Białe Błota</h4>
            </div>
          </div>
        </div>
        <small>
          Copernicus Apps spółka z ograniczoną odpowiedzialnością z siedzibą w
          Białych Błotach (86-005) przy Chlebowa 33/6, KRS: 0000992078 (Sąd
          Rejonowy w Bydgoszczy, XIII Wydział Gospodarczy Krajowego Rejestru
          Sądowego), NIP: 5543006162, REGON: 52314729600000. Kapitał zakładowy w
          wysokości 50000,00 zł.
        </small>
        <div ref={bottomRef} />
      </footer>
      <div className={loaded ? "loader" : "loader show"}>
        <Loader />
      </div>
    </div>
  )
}

export default IndexPage

export const query = graphql`
  query {
    image1: file(relativePath: { eq: "gw01.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    image2: file(relativePath: { eq: "gw02.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    image3: file(relativePath: { eq: "gw03.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    image4: file(relativePath: { eq: "ff01.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    image5: file(relativePath: { eq: "ff02.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    image6: file(relativePath: { eq: "ff03.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
